<template>
  <div
    v-if="content"
    v-html="content"
    class="m-checkout-footer-copyright"
  />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MFooterCopyright',
  data () {
    return {
      content: null
    }
  },
  async created () {
    const response = await this.loadCmsBlockByIdentifier({
      value: 'footer-copyright'
    })
    this.content = response.content
  },
  methods: {
    ...mapActions({
      loadCmsBlockByIdentifier: 'cmsBlock/single'
    })
  }
}
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-checkout-footer-copyright {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-start;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-10);
  font-weight: var(--font-normal);
  line-height: 1.2;
  color: var(--dark-gray);

  p {
    margin: 0 0 var(--spacer-11);

    @media only screen and (min-width: $tablet-min) {
      margin: 0;
    }
  }

  @media only screen and (min-width: $tablet-min) {
    justify-content: flex-end;
    align-items: flex-start;
  }
}
</style>
