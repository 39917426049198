<template>
  <footer
    class="o-checkout-footer"
  >
    <div class="o-checkout-footer__container">
      <div>
        <div class="o-checkout-footer__payments">
          {{ $t('When paying for Services using electronic payments') }}
        </div>
      </div>
      <MFooterCopyright />
    </div>
  </footer>
</template>

<script>
import MFooterCopyright from 'theme/components/molecules/m-footer-copyright'
export default {
  name: 'OCheckoutFooter',
  components: {
    MFooterCopyright
  },
  data () {
    return {}
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-checkout-footer {
  box-sizing: border-box;
  border-top: 2px solid var(--light-gray);
  margin-top: var(--spacer-10);
  padding: 0 var(--spacer-24);
  width: 100%;

  @media only screen and (min-width: $tablet-min) {
    margin-top: 0;
    padding: 0 var(--spacer-10);
  }

  &__container {
    margin: 0 auto;
    padding: var(--spacer-7) 0;
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $tablet-min) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__payments {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-10);
    font-weight: var(--font-normal);
    line-height: 1.2;
    color: var(--dark-gray);
    margin-bottom: var(--spacer-15);
    max-width: 630px;
    gap: var(--spacer-20);
  }
}
</style>
