import { render, staticRenderFns } from "./m-footer-copyright.vue?vue&type=template&id=095717b8"
import script from "./m-footer-copyright.vue?vue&type=script&lang=js"
export * from "./m-footer-copyright.vue?vue&type=script&lang=js"
import style0 from "./m-footer-copyright.vue?vue&type=style&index=0&id=095717b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports